@import "../../../styles/vars";
@import "../../../styles/mixins";

.header {
  background: radial-gradient(50% 50% at 76% 50%, #{$color-black-to-white-gradient });

  &__container {
    padding-top: 124px;
    padding-bottom: 40px;

    @include vxlScreenMedia {
      padding-top: 104px;
      padding-bottom: 16px;
    }

    @include lgScreenMedia {
      padding-top: 40px;
      padding-bottom: 10px;
    }

    @include mdScreenMedia {
      padding-top: 40px;
    }
    @include smScreenMedia {
      padding-bottom: 20px;
    }
  }

  &__main {
    @include flexCol(space-between);
    gap: 50px;

    @include mdScreenMedia {
      flex-direction: column;
    }
  }

  &__mainTextBlock {
    max-width: 700px;
    width: 100%;
  }

  &__mainImgBlock {
    width: 55%;
    max-width: 864px;

    @include mdScreenMedia {
      width: 100%;
      max-width: none;
    }
  }

  &__mainImg {
    width: 100%;
  }

  &__footBlock {
    margin-top: 95px;
    @include flexCol(space-between);
    gap: 50px;

    @include xxlScreenMedia {
      margin-top: 78px;
    }

    @include mdScreenMedia {
      margin-top: 55px;
    }

    @include smScreenMedia {
      margin-left: -20px;
      margin-right: -20px;
    }
  }


}