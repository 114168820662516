@import "../../../styles/vars";
@import "../../../styles/mixins";


.mainBtn {
  background: $color-white;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.12), -4px -4px 12px rgba(226, 226, 226, 0.08);
  border-radius: 8px;
  padding: 15px 10px 18px;
  @include setFont(20px, 500);
  border: none;
  cursor: pointer;
  transition: $transition;
  white-space: nowrap;
  min-width: 148px;
  padding-left: 27px;
  padding-right: 27px;
  color: $color-black-to-light-black;

  @include vxlScreenMedia {
    min-width: 114px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  @include xlScreenMedia {
    min-width: 102px;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  @include lgScreenMedia {
    font-size: 14px;
  }

  &_big {
    @include smScreenMedia {
      min-width: 206px;
    }
  }

  &:hover {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.12), -4px -4px 12px rgba(226, 226, 226, 0.2);
  }

  &:active {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.12), -4px -4px 12px rgba(226, 226, 226, 0.3);
  }

  &:disabled {
    background: #C0C0C0;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.12), -4px -4px 12px rgba(226, 226, 226, 0.08);
    cursor: default;
  }

  &_big {
    @include smScreenMedia {
      min-width: 206px;
    }
  }

  &_light {
    &:hover {
      outline: 1px solid $color-gold;
    }

    &:active {
      outline: 2px solid $color-gold;
    }

    &:disabled {
      background: #C0C0C0;
      box-shadow: none;
    }
  }
}