@import "../../../styles/vars";
@import "../../../styles/mixins";

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  &__head {
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
    background: $color-black-to-white;

    &_active {
      box-shadow: none;
    }
  }

  &__headContainer {
    padding-top: 20px;
    padding-bottom: 20px;
    transition: $transition;

    &_active {
      @media (min-width: 1025px) {
        padding-bottom: 44px;
      }
    }
  }

  &__headWrapper {
    @include flexCol(center);
    position: relative;
    transition: $transition;

    @include lgScreenMedia {
      flex-direction: row-reverse;
      justify-content: space-between;
    }


  }

  &__burgerBtn {
    border: none;
    cursor: pointer;
    background: transparent;
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    transition: $transition;
    opacity: 0;
    visibility: hidden;

    @include lgScreenMedia {
      position: static;
    }

    &_visible {
      opacity: 1;
      visibility: visible;
    }

    .line1 {
      transform-origin: left;
    }

    .line3 {
      transform-origin: left;
      transform: rotate(360deg);
    }

    &_active {
      .line1 {
        transform: rotate(45deg);
      }

      .line2 {
        margin-left: -20px;
        opacity: 0;
      }

      .line3 {
        transform: rotate(315deg) !important;
      }
    }
  }

  &__burgerBtnLine {
    background: $color-gold;
    width: 32px;
    height: 3px;
    border-radius: 3px;
    transition: $transition;

    .line3 {
      transform-origin: left;
      transform: rotate(360deg);
    }
  }


  &__logoText {
    @include svg(194px, $color-white-to-black, 44px);

    @include lgScreenMedia {
      display: none;
    }


  }

  &__headLogoIcon {
    @include svg(35px, $color-gold, 48px);
    display: none;

    @include lgScreenMedia {
      display: block;
    }
  }

  &__centerLine {
    width: 100%;
    border-bottom: 1px solid $color-white-to-black;
    position: relative;
    z-index: 1;

    @include lgScreenMedia {
      display: none;
    }
  }

  &__centerLineLogoBlock {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: $color-black-to-white;
    padding: 0 36px;
  }

  &__centerLineLogoImg {
    @include svg(42px, $color-gold, 57px)
  }

  &__main {
    transform-origin: top;
    background: $color-black-to-white;

    @include lgScreenMedia {
      display: none;
    }

    &_active {
      box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
    }
  }

  &__menu {
    @include flexCol(space-around);
    padding-top: 35px;
    padding-bottom: 20px;
  }

  &__menuLink {
    cursor: pointer;
    text-decoration: none;
    color: $color-white-to-black;
    @include setFont(20px, 400);
    white-space: nowrap;
    @include flexCol();

    &_gold {
      color: $color-gold;
    }

    &_activeDropBtn {
      transition: none;
      border-bottom: 1px solid $color-gold;
      margin-bottom: -1px;

    }

    &_activeLink {
      border-bottom: 1px solid $color-gold;
      margin-bottom: -1px;
    }

    &_activeLink, &:hover {
      border-bottom: 1px solid $color-gold;
      margin-bottom: -1px;
    }
  }

  &__menuDropdownIcon {
    @include svg(12px, $color-white-to-black);
    margin-left: 8px;

    &_active {
      transform: rotate(180deg);
    }
  }

  &__menuDropdownContent {
    position: absolute;
    @include flexCol(flex-start, flex-start);
    flex-direction: column;
    padding: 19px 27px;
    gap: 20px;
    width: 230px;
    z-index: 9;
    left: 0;
    box-shadow: $box-shadow-def;
    border-radius: 12px;
    border: 1px solid $color-gold;
    background: $color-black-to-white;
    margin-top: 14px;

    &_light {
      border: 1px solid #265787;
    }
  }

  &__menuDropdown {
    position: relative;
  }

  &__menuDropdownLinks {
    text-decoration: none;
    @include setFont(20px, 300);
    transition: $transition;
    color: $color-white-to-black;

    &:hover {
      color: $color-white-to-black-hover;
    }

    &:active {
      color: $color-white-to-black-active;
    }

    &:hover {
      color: $color-white-to-black-hover;
    }

    &_def {
      color: $color-white-to-black;
    }

    &_active {
      color: $color-white-to-black-active;
    }
  }

}
