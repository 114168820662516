@import "vars";

@mixin smScreenMedia {
  @media (max-width: $sm-screen) {
    @content;
  }
}

@mixin mdScreenMedia {
  @media (max-width: $md-screen) {
    @content;
  }
}

@mixin lgScreenMedia {
  @media (max-width: $lg-screen) {
    @content;
  }
}

@mixin xlScreenMedia {
  @media (max-width: $xl-screen) {
    @content;
  }
}

@mixin xxlScreenMedia {
  @media (max-width: $xxl-screen) {
    @content;
  }
}

@mixin vxlScreenMedia {
  @media (max-width: $vxl-screen) {
    @content;
  }
}


@mixin svg($width,$color,$height: $width) {
  width: $width;
  height: $height;
  fill: $color;
  transition: $transition;
}

@mixin setFont($size,$weight) {
  font-size: $size;
  font-weight: $weight;

  @if ($size == 20px) {
    @include vxlScreenMedia {
      font-size: 16px;
    }

    @include xxlScreenMedia {
      font-size: 14px;
    }
  } @else if($size == 16px) {
    @include vxlScreenMedia {
      font-size: 14px;
    }

    @include xxlScreenMedia {
      font-size: 12px;
    }
  }
}

@mixin flexCol($layout: center,$align: center) {
  display: flex;
  justify-content: $layout;
  align-items: $align;
}

@mixin hover {
  @media (hover: hover) {
    @content;
  }
}