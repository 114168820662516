@import "../../../styles/vars";
@import "../../../styles/mixins";

.secondaryBtnBlock {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.12), -4px -4px 12px rgba(226, 226, 226, 0.08);
  border-radius: 8px;
  width: fit-content;


  &_light {
    display: block;

    .secondaryBtnBlock__btn {
      box-shadow: none;
    }
  }

  &__btn {
    background: $color-black-to-gold;
    padding: 15px 40px 18px;
    @include setFont(20px, 500);
    color: $color-white;
    cursor: pointer;
    white-space: nowrap;
    box-shadow: 0 0 0 1px $color-gold;
    border-radius: 8px;
    transition: $transition;
    border: none;
    min-width: 148px;
    text-align: center;


    @include vxlScreenMedia {
      padding: 11px 33px;
      min-width: 114px;

    }

    @include xlScreenMedia {
      padding: 9px 17px;
      min-width: 102px;
    }

    @include lgScreenMedia {
      padding: 12px 31px;
    }

    @include smScreenMedia {
      max-width: 206px;
      width: 100%;
      padding: 11px 0 14px;
    }

    &:hover {
      box-shadow: 0 0 0 1.5px $color-gold-to-white;
    }

    &:active {
      box-shadow: 0 0 0 2px $color-gold;
    }

    &:disabled {
      background: $color-grey;
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.12), -4px -4px 12px rgba(226, 226, 226, 0.08);
    }
  }


}
