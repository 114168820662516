@import "../../../styles/vars";
@import "../../../styles/mixins";

.messageModal {
  position: fixed;
  left: 50%;
  top: 50%;
/*  max-width: 794px;
  max-height: 466px;*/
  max-width: 680px;
  max-height: 340px;
  width: 100%;
  height: 100%;
  transform: translate(-50%,-50%);
  padding: 0 20px;
  @include flexCol();
  z-index: 999;

  &__box {
    background: $color-black-to-white;
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/images/messageModal/messageModalBg.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    @include flexCol();
    flex-direction: column;
    padding: 20px;

    &_light {
      background-image: url('../../../assets/images/messageModal/messageModalBgLight.png');
    }
  }

  &__title {
    margin-bottom: 40px;
    text-align: center;
    max-width: 550px;
    @include setFont(30px, 500);
    color: $color-white-to-black;
    line-height: 44px;
  }

}

