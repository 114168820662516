@import "../../../styles/vars";
@import "../../../styles/mixins";

.burgerMenu {
  width: 100%;
  background: $color-black-to-white;

  &__dropBtn {
    cursor: pointer;
  }

  &__navItems {
    text-decoration: none;
    color: $color-white-to-black;
    @include setFont(22px, 500);
    padding-bottom: 7px;
    border-bottom: 1px solid;
    display: block;
    cursor: pointer;

    &:not(:first-child) {
      padding-top: 22px;
    }

    @include smScreenMedia {
      font-size: 20px;
    }
  }


  &__arrowDownIcon {
    @include svg(12px, $color-white-to-black);
    margin-left: 8px;

    &_active {
      transform: rotate(180deg);
    }
  }

  &__dropdownContent {
    @include flexCol(flex-start, flex-start);
    flex-direction: column;
    gap: 16px;
    padding: 14px 0 20px;
  }

  &__dropdownLink {
    @include setFont(22px, 300);
    color: $color-white-to-black;
    text-decoration: none;
  }

  &__arrowRightIcon {
    @include svg(12px, $color-white-to-black);
    margin-left: 8px;
  }

  &__darkLightOption {
    position: absolute;
    right: 20px;
    bottom: 40px;
  }

  &__dropdownWrapper {
    overflow: hidden;
  }

  &__userName {
    @include setFont(24px, 600);
    text-decoration: none;
    color: $color-white-to-black;
    margin-bottom: 20px;
    display: block;
  }
}