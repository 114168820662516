@import "../../../styles/vars";
@import "../../../styles/mixins";

.slider {
  width: 100%;
  background: $color-black-to-white;

  &__imgBlock {
    padding: 10px;
    width: 100%;
    @include flexCol()
  }

  &__picture {
    width: 100%;
    height: 100%;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__imgBlockInner {
    border-radius: $border-radius-def;
    @include flexCol();
    overflow: hidden;
    height: 664px;
    padding: 55px 80px;
    width: 100%;
    max-width: 864px;

    @include vxlScreenMedia {
      height: 520px;
      max-width: 664px;
    }

    @include xxlScreenMedia {
      height: 450px;
      max-width: 564px;
    }

    @include lgScreenMedia {
      height: 340px;
      padding: 55px 40px;
      max-width: 464px;
    }

    @media (max-width: 420px) {
      height: 260px;
      padding: 25px 20px;
    }

    @media (max-width: 340px) {
      height: 200px;
    }
  }

  :global(.slick-dots) {
    bottom: -30px;
  }
  :global(.slick-dots .slick-active div) {
    background: $color-dot-active;
  }
}