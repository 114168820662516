@import "../../../styles/vars";
@import "../../../styles/mixins";

.footer {
  background: #2E2E2E;

  &_light {
    background: #F9F9FA;;
  }


  &__main {
    @include flexCol(space-between, flex-start);
    padding: 148px 0 98px;

    @include vxlScreenMedia {
      padding-top: 142px;
      padding-bottom: 78px;
    }

    @include xxlScreenMedia {
      padding-top: 52px;
      padding-bottom: 52px;
    }

    @include xlScreenMedia {
      padding-top: 42px;
      padding-bottom: 39px;
    }

    @media(max-width: 992px) {
      flex-direction: column;
      align-items: center;
    }

    @include mdScreenMedia {
      padding-top: 20px;
      padding-bottom: 43px;
    }
  }

  &__logo {
    max-width: 198px;
    @include flexCol();
    width: 100%;


    @include vxlScreenMedia {
      max-width: 150px;
    }

    @include xxlScreenMedia {
      max-width: 138px;
    }

    @include xlScreenMedia {
      max-width: 114px;
    }

    @include lgScreenMedia {
      margin-bottom: 30px;
    }

    @include smScreenMedia {
      margin-bottom: 20px;
    }
  }

  &__logoImg {
    width: 133px;
    height: 185px;

    @include vxlScreenMedia {
      width: 115px;
      height: 157px;
    }

    @include xxlScreenMedia {
      width: 90px;
      height: 127px;
    }
  }

  &__title {
    @include setFont(20px, 500);
    margin-bottom: 20px;
    color: $color-white-to-black;
  }

  &__linksBlock {
    @media (max-width: 992px) {
      display: none;
    }
  }

  &__link {
    @include setFont(20px, 300);
    text-decoration: none;
    color: $color-white-to-black;
    margin-bottom: 12px;
    @include flexCol(flex-start);
    gap: 2px;
    transition: $transition;

    &:hover {
      color: $color-white-to-black-hover;
    }


    &:active {
      color: $color-white-to-black-active;
    }


    @include vxlScreenMedia {
      margin-bottom: 15px;
    }

    @include xxlScreenMedia {
      margin-bottom: 6px;
    }

    @include mdScreenMedia {
      font-size: 14px;
      margin-bottom: 14px;
    }
  }

  &__contactImg {
    width: 20px;
    transition: $transition;
    margin-right: 6px;
  }

  &__subscribeBlock {
    max-width: 460px;
    width: 100%;

    @include vxlScreenMedia {
      max-width: 370px;
    }

    @include vxlScreenMedia {
      max-width: 322px;
    }

    @include vxlScreenMedia {
      max-width: 280px;
    }
  }

  &__subscribeTitle {
    @media (max-width: 992px) {
      display: none;
    }
  }

  &__subscribeForm {
    @media (max-width: 992px){
      flex-direction: column;
      box-shadow: none;
      border-radius: 0;
      gap: 34px;
    }

    div {
      @media (max-width: 992px){
        position: static;
      }
    }

    input {
      @media (max-width: 992px){
        border-radius: 8px;
        box-shadow: $box-shadow-input;
        padding: 12px !important;
      }
    }

    button {
      @media (max-width: 992px){
        max-width: 206px;
        display: block;
        margin: 0 auto;
      }
    }
  }

  &__contentText {
    margin-bottom: 33px;

    @include smScreenMedia {
      margin-bottom: 30px;
    }
  }

  &__endingBlock {
    padding: 16px 0 27px;
    @include flexCol(space-between);
    border-top: 1px solid $color-white-to-black;

    @media (max-width: 992px) {
      justify-content: center;
      padding: 16px 0;
    }

    @include smScreenMedia {
      padding: 8px 0;
    }
  }

  &__privacyBlock {
    @include flexCol();
    gap: 34px;
  }

  &__privacyLink {
    text-decoration: none;
  }

  &__endingBlockText {
    @include setFont(16px, 500);
    color: $color-white-to-black;
    text-decoration: none;

    @include smScreenMedia {
      font-weight: 300;
    }

    &_mob {
      text-align: center;
      margin-top: 10px;
      display: none;

      @include lgScreenMedia {
        display: block;
      }
    }
  }

  &__socialIcons {
    gap: 10px;

    @media (max-width: 992px) {
      display: none;
    }

    img {
      width: 34px;
    }
  }

  &__socialIconsMob {
    display: none;
    gap: 10px;

    @media (max-width: 992px) {
      display: flex;
    }

    @include smScreenMedia {
      margin-bottom: 38px;
    }

    img {
      width: 32px;
    }
  }

  &__linksWrapperMob {
    @include flexCol(center, flex-start);
    width: 100%;
    margin-top: 30px;
    display: none;
    flex-direction: column;

    @include smScreenMedia {
      margin-top: 0;
    }

    @media (max-width: 992px) {
      display: flex;
    }
  }

  &__linksBlockMob {
    width: 100%;
    border-bottom: 1px solid $color-white-to-black;
  }

  &__titleMob {
    color: $color-white-to-black;
    @include setFont(14px, 500);
    margin-top: 20px;

    padding: 11px 0 9px;
    cursor: pointer;
    @include flexCol(space-between)
  }

  &__dropdownContent {
    overflow: hidden;
  }

  &__dropdownIcon {
    @include svg(12px, $color-white-to-black);

    &_active {
      transform: rotate(180deg);
    }
  }

  &__linkMob {
    @include setFont(14px, 300);
    text-decoration: none;
    color: $color-white-to-black;
    margin-top: 14px;
    line-height: 14px;
    @include flexCol(flex-start);
    gap: 2px;
    transition: $transition;

    @include lgScreenMedia {
      height: 16px;
    }

    &:hover {
      color: $color-white-to-black-hover;
    }

    &:active {
      color: $color-white-to-black-active;
    }
  }

  &__privacyLinkDesk {
    @media (max-width: 992px) {
      display: none;
    }
  }

  &__privacyLinkMob {
    display: none;

    @include lgScreenMedia {
      display: block;
    }
  }

}