@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.header {
  &__mainTextBlock {
    max-width: 658px;
    width: 100%;
  }

  &__title {
    @include setFont(104px, 500);
    line-height: 70px;
    color: $color-white-to-black;
    margin-bottom: 40px;

    @include vxlScreenMedia {
      font-size: 74px;
      line-height: 46px;
      margin-bottom: 26px;
    }

    @include xxlScreenMedia {
      font-size: 64px;
      line-height: 40px;
      margin-bottom: 19px;
    }

    @include xlScreenMedia {
      font-size: 50px;
      line-height: 30px;
    }

    @include smScreenMedia {
      font-size: 40px;
      line-height: 28px;
      margin-bottom: 15px;
    }
  }
  &__contentText {
    text-align: justify;
    gap: 10px;
    display: flex;
    flex-direction: column;
  }
}



@keyframes title-shadow {
  0%{
    color: $color-white-to-black;
    text-shadow: none;
  }
  100%{
    color: $color-white-to-black;
    text-shadow: 4px 4px 0 rgba(#000, 0.25);
  }
}