@import "../../../styles/vars";
@import "../../../styles/mixins";

.profileWrapper {
  background: radial-gradient(50% 47% at 76% 50%, #{$color-black-to-white-gradient });
  padding-top: 124px;
  padding-bottom: 160px;

  @include vxlScreenMedia {
    padding-top: 104px;
    padding-bottom: 74px;
  }

  @include xxlScreenMedia {
    padding-top: 80px;
  }

  @include lgScreenMedia {
    padding-top: 40px;
    padding-bottom: 54px;
  }

  @include mdScreenMedia {
    padding-top: 40px;
    padding-bottom: 40px;

  }
}