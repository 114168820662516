@import "../../../styles/vars";
@import "../../../styles/mixins";


.profileForm {
  box-shadow: $box-shadow-def;
  border-radius: 0 0 $border-radius-def $border-radius-def;
  background: $color-black-to-white;
  padding: 130px 0 40px;
  position: relative;

  @include smScreenMedia {
    padding-top: 60px;
  }

  &__logoutBtn {
    top: 50px;
    right: 25px;

    @include smScreenMedia {
      top: 20px;
      right: 20px;
    }
  }

  &__form {
    @include flexCol();
    flex-wrap: wrap;
    column-gap: 74px;
    max-width: 1283px;
    margin: 0 auto;
    position: relative;
    padding: 0 20px;

    @include lgScreenMedia {
      column-gap: 37px;
    }

    @include mdScreenMedia {
      flex-direction: column;
    }
  }

  &__field {
    width: calc(100% / 3 - 50px);
    @include flexCol(flex-start, stretch);
    flex-direction: column;
    margin-bottom: 40px;

    @include smScreenMedia {
      margin-bottom: 20px;
    }

    @include mdScreenMedia {
      width: 100%;
    }

    @include mdScreenMedia {
      &_first_name {
        order: 1;
      }

      &_email {
        order: 3;
      }

      &_password {
        order: 5;
      }

      &_last_name {
        order: 2;
      }

      &_phone {
        order: 4;
      }

      &_confirm_password {
        order: 6;
      }
    }

  }



  &__label {
    @include setFont(20px, 300);
    color: $color-white-to-black;
    margin-bottom: 20px;
  }

  &__labelOpt {
    @include setFont(12px,300)
  }

  &__input {
    width: 100%;
  }

  &__checkBox {
    position: absolute;
    left: 37px;
    bottom: 20px;

    @include mdScreenMedia {
      position: static;
      align-self: flex-start;
      margin-bottom: 20px;
      order: 6;
    }
    a {
      color: $color-white-to-black;
      text-decoration: underline;
      text-underline-offset: 6px;
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__btnBlock {
    display: block;
    margin: 0 auto;

    @include mdScreenMedia {
      order: 7;
    }
  }

  &__btn {
    display: block;
    margin: 0 auto;
  }

  &__invalidText {
    margin: -28px 0 7px;

    @include lgScreenMedia {
      margin-top: -25px;
    }
  }

}
