@import "../../../styles/vars";
@import "../../../styles/mixins";

.orderBtn {
  @include mdScreenMedia {
    min-width: 206px;
  }

  @media (max-width: 320px) {
    min-width: 172px;
  }
}