//@import "mixins";

// Colors

$color-black: var(--color-black);
$color-white: var(--color-white);
$color-blue: var(--color-blue);
$color-gold: var(--color-gold);
$color-light-black: var(--color-light-black);

$color-hover-opacity: var(--color-hover-opacity);
$color-body-bg: var(--color-body-bg);
$color-black-to-white: var(--color-black-to-white);
$color-black-to-light-black: var(--color-black-to-light-black);
$color-white-to-black: var(--color-white-to-black);
$color-white-to-gold: var(--color-white-to-gold);
$color-dot-active: var(--color-dot-active);
$color-black-to-white-gradient: var(--color-black-to-white-gradient);
$color-black-to-gold: var(--color-black-to-gold);
$color-gold-to-white: var(--color-gold-to-white);

$box-shadow-def: var(--box-shadow-def);

$color-grey: #414141;
$color-white-to-black-hover: #B1B1B1;
$color-white-to-black-active: #989898;


$font-fam-def: 'Poppins', sans-serif;

// ----

$margin-container-def: 160px;
$margin-container-tablet-def: 80px;
$margin-container-mob-def: 40px;

$box-shadow-input: inset -4px -4px 12px rgba(226, 226, 226, 0.08), inset 4px 4px 12px rgba(0, 0, 0, 0.12);
$border-radius-def: 20px;
$transition: all .5s;

// medias
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 1024px;
$xl-screen: 1200px;
$xxl-screen: 1400px;
$vxl-screen: 1600px;