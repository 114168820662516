@import "../../../styles/vars";

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 100%);

  &_smallZIndex {
  z-index: 888;
  }
  &_highZIndex {
    z-index: 999;
  }

  &_light {
    background: linear-gradient(180deg, rgba(180, 151, 90, 0.12) 50%, rgba(180, 151, 90, 0) 100%);
  }
}