@import "../../../styles/vars";
@import "../../../styles/mixins";

.socialIcons {
  @include flexCol();

  &__img {
  transition: $transition;
    &:hover {
      opacity: 0.6;
    }
  }
}
