@import "vars";
@import "mixins";

:root {
  --color-black: #282828;
  --color-white: #FFFFFF;
  --color-blue: #2F6EFF;
  --color-gold: #B4975A;
  --color-light-black: #6A6969;
  --color-body-bg: var(--color-black);
  --color-black-to-white: var(--color-black);
  --color-white-to-black: var(--color-white);
  --color-white-to-gold: var(--color-white);
  --color-black-to-gold: var(--color-black);
  --color-black-to-light-black: var(--color-black);
  --color-gold-to-white: var(--color-gold);
  --color-hover-opacity: #FFFFFF1a;
  --color-black-to-white-gradient: 'rgba(180 151 90 0.072) 0%, rgba(180, 151, 90, 0) 100%';
  --color-dot-active: #767676;
  --box-shadow-def: 4px 4px 4px rgba(0, 0, 0, 0.12), -2px -2px 12px rgba(226, 226, 226, 0.06);
}


*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*:not(i) {
  font-family: $font-fam-def;

}

body {
  background: $color-body-bg;
  overflow-x: hidden;
  overflow-y: hidden;

  &.offset {
    overflow-y: visible;
    margin-top: 236px;

    @include lgScreenMedia {
      margin-top: 88px;
    }
  }
}

.slick-list {
  display: flex;
  align-items: center;
}

.container {
  max-width: 1682px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
  padding-left: 20px;

  @include vxlScreenMedia {
    max-width: 1252px;
  }

  @include xxlScreenMedia {
    max-width: 1081px;
  }

  @media (max-width: 1200px) {
    max-width: 1081px;
  }

  @include lgScreenMedia {
    max-width: 100vw;
  }
}

.contentText {
  @include setFont(18px, 300);
  line-height: 30px;
  color: $color-white-to-black;

  @include vxlScreenMedia {
    line-height: 24px;
    font-size: 16px;
  }

  @include lgScreenMedia {
    font-size: 14px;
  }

  @include xlScreenMedia {
    line-height: 21px;
  }
}

.shadowedBox {
  border-radius: $border-radius-def;
  box-shadow: $box-shadow-def;
  border-collapse: collapse;
}

.titleDef {
  @include setFont(40px, 500);
  color: $color-white-to-black;
  line-height: 44px;

  @include vxlScreenMedia {
    font-size: 30px;
    line-height: 33px;
  }

  @include lgScreenMedia {
    font-size: 26px;
    line-height: 28px;
  }

  @include smScreenMedia {
    font-size: 22px;
    line-height: 24px;
  }
}

.inputBlockDef {
  box-shadow: $box-shadow-input;
  -webkit-box-shadow: $box-shadow-input;
  border-radius: 8px;
  background: $color-black-to-white;
}

.inputDef {
  background: transparent;
  border-radius: 8px;
  -webkit-appearance: none;
  padding: 12px;
  border: none;
  @include setFont(20px, 300);
  color: $color-white-to-black;
  outline: none;
  transition: $transition;

  ::placeholder {
    color: rgba($color-white-to-black, 0.4);
  }


  &:hover, &:focus, &_active {
    box-shadow: 0 0 0 1px $color-white-to-gold;
  }
}

.scrollbarDef {
  cursor: auto;

  &::-webkit-scrollbar {
    cursor: default;
    background: $color-white-to-black;
    border-radius: 36px;
    margin-top: 16px;
    margin-right: 9px;
    background-clip: padding-box;
    width: 16px;
  }

  &::-webkit-scrollbar-thumb {
    cursor: default !important;
    width: 14px;
    border-radius: 36px;
    background-clip: padding-box;
    border: 1px solid $color-white-to-black;
    background: $color-black-to-white;
  }
}

.invalidText {
  color: #E13838;
  @include setFont(14px, 500);
  margin-left: 2px;

  @include lgScreenMedia {
    font-size: 12px;
  }

  &_absolute {
    margin-top: 8px;
    margin-bottom: -29px;

    @include lgScreenMedia {
      margin-bottom: -26px;
    }
  }
}

.linkDef {
  color: $color-white-to-black;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $color-white-to-black-hover;
  }

  &:active {
    color: $color-white-to-black-active;
  }
}

//chat bot
.tidio-135wcf7 {
  display: none;
}


//cookie popup
#cookie-notification {
  border-radius: 15px;
}

#cookie-notification__decline, #cookie-notification__accept {
  padding: 15px 55px;
}

.enzuzo-notification-text {
  margin: 52px 0 10px 0
}

.enzuzo-notification-text:before {
  position: absolute;
  top: -62px;
  content: url(../../src/assets/svg/cookie.svg);
}


.goldText {
  color: $color-gold;
}
