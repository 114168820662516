@import "../../../styles/vars";
@import "../../../styles/mixins";

.brandsIcons {
  @include flexCol(flex-start);
  position: relative;
  height: 50px;

  @include lgScreenMedia {
    max-width: 100%;
    display: none;
  }

  @for $i from 0 through 7 {
    & div:nth-child(#{$i + 1}) img {
      animation-delay: $i * 2s;
    }
  }

  &__marqueContainer {
    display: none;
    align-items: center;
    width: 100%;
    overflow: hidden;

    @include lgScreenMedia {
      display: flex;
    }
  }

  &__marque {
    display: none;
    min-width: 100%;
    display: flex;
    align-items: center;
    animation: marque 14s linear infinite;

    @include lgScreenMedia {
      display: flex;
    }

    @include mdScreenMedia {
      min-width: 130%;
    }

    @include smScreenMedia {
      min-width: 180%;
    }

    @media (max-width: 420px) {
      min-width: 245%;
    }

    @for $i from 4 through 7 {
      & div:nth-child(#{$i}) img {
        animation-delay: $i * 2 - 4 * 2s;
      }
    }
    @for $i from 1 through 3 {
      & div:nth-child(#{$i}) img {
        animation-delay: $i * 2 + 6s;
      }
    }

    @for $i from 3 through 7 {
      & div:nth-child(#{$i}) img {
        @include smScreenMedia {
          animation-delay: $i * 2 - 3 * 2s;
        }
      }
    }

    @for $i from 1 through 2 {
      & div:nth-child(#{$i}) img {
        @include smScreenMedia {
          animation-delay: $i * 2 + 8s;
        }
      }
    }


  }


  &__img {
    filter: grayscale(1);
    transition: $transition;
    max-width: 100%;
    animation: grayScale 14s linear infinite;
    padding: 0 10px;

    @include lgScreenMedia {
      //animation: none;
      //animation-duration: 7s;
    }

    &:hover {
      filter: grayscale(0);
    }


  }
}


@keyframes grayScale {
  0%, 20%, 100% {
    filter: grayscale(1)
  }
  10% {
    filter: grayscale(0)
  }
}

@keyframes marque {
  from {
    transform: translateX(0%)
  }
  to {
    transform: translateX(-100%)
  }
}