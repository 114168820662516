@import "../../../styles/vars";
@import "../../../styles/mixins";

.closeBtn {
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background: $color-black-to-white;
  width: 50px;
  height: 50px;
  @include flexCol();
  position: absolute;
  box-shadow: -4px -4px 12px rgba(226, 226, 226, 0.08), 4px 4px 12px rgba(0, 0, 0, 0.12);
  transition: $transition;
  z-index: 999;

  @include mdScreenMedia {
    width: 30px;
    height: 30px
  }

  &:hover {
    outline: 1px solid $color-white-to-gold;
  }

  &:active {
    outline: 2px solid $color-white-to-gold;
  }

  &:disabled {
    background: $color-grey;;
  }


  &__icon {
    @include svg(26px, $color-white-to-black);

    @include mdScreenMedia {
      @include svg(18px, $color-white-to-black)

    }
  }
}

