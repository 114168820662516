@import "../../../styles/vars";
@import "../../../styles/mixins";


.galleryHeader {
  background: radial-gradient(50% 50% at 76% 50%, #{$color-black-to-white-gradient });


  &__container {
    padding-top: 124px;
    padding-bottom: 121px;

    @include vxlScreenMedia {
      padding-top: 104px;
      padding-bottom: 74px;
    }

    @include xxlScreenMedia {
      padding-top: 80px;
    }

    @include lgScreenMedia {
      padding-top: 40px;
      padding-bottom: 54px;
    }

    @include mdScreenMedia {
      padding-top: 40px;
      padding-bottom: 40px;

    }
  }

  &__main {
    @include flexCol(space-between);
    gap: 50px;

    @include mdScreenMedia {
      flex-direction: column;
    }
  }

  &__mainTextBlock {
    max-width: 700px;
    width: 100%;
  }

  &__title {
    @include setFont(54px, 500);
    line-height: 60px;
    color: $color-white-to-black;
    margin-bottom: 40px;

    @include vxlScreenMedia {
      font-size: 42px;
      line-height: 46px;
      margin-bottom: 26px;
    }

    @include xxlScreenMedia {
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 19px;
    }

    @include xlScreenMedia {
      font-size: 30px;
      line-height: 30px;
    }

    @include smScreenMedia {
      font-size: 26px;
      line-height: 28px;
      margin-bottom: 16px;
    }
  }

  &__mainImgBlock {
    width: 55%;

    @include mdScreenMedia {
      width: 100%;
    }
  }

  &__slider {
      border-radius: 20px;
    img {
      border-radius: 20px;
    }

    :global(.shadowedBox) {
      overflow: hidden;
    }
    :global(.slick-dots){
      position: absolute!important;
    }
  }

  &__sliderDots {
    position: absolute;
  }

  &__footBlock {
    margin-top: 95px;
    @include flexCol(space-between);
    gap: 50px;

    @include xxlScreenMedia {
      margin-top: 78px;
    }

    @include lgScreenMedia {
      margin-left: -20px;
      margin-right: -20px;
    }

    @include mdScreenMedia {
      margin-top: 55px;
    }

  }

  &__mainContentText {
    text-align: justify;
  }

}