@import "../../styles/vars";
@import "../../styles/mixins";

.loadingBlock {
  width: 100%;
  height: 100vh;
  @include flexCol();
  position: fixed;
  top: 0;
  left: 0;
  background: $color-black-to-white;
  z-index: 999999999;

  &_hidden {
    display: none;
  }

  &__imgBlock {
    max-width: 200px;
    width: 100%;
    position: relative;

  }

  &__img {
    width: 100%;
  }

  &__overlay {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    background: $color-body-bg;
    animation: loading 2s;
    animation-fill-mode: forwards;
  }

}


@keyframes loading {
  from {height: 100%}
  to {height: 0%}
}