@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.aboutSlider {
   padding: 0;
  &__container {
    padding-top: 8%;

    @include smScreenMedia {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 0;
      padding-right: 0;
      padding-top: 34px;
    }
  }

  &__sliderItem {
    padding: 10px;

    @include smScreenMedia {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  &__sliderItemInner {
    width: 100%;
    @include flexCol();
    position: relative;
    overflow: hidden;
    border-radius: $border-radius-def;
    border: 14px solid $color-black-to-white;
    box-shadow: -2px -2px 12px rgba(226, 226, 226, 0.06), 4px 4px 12px rgba(0, 0, 0, 0.12);
  }

  &__sliderItemInner:hover &__slideOverlay {
    opacity: 1;
  }

  &__sliderLoader {
    margin: 0 auto;
  }
  &__sliderItemImg {
    width: 100%;
    background-color: $color-black-to-white;
    box-shadow: -2px -2px 12px rgba(226, 226, 226, 0.06), 4px 4px 12px rgba(0, 0, 0, 0.12);
    animation: displaying .5s linear;
    animation-fill-mode: forwards;
  }

  &__loaderBlock {
    @include flexCol();
    gap: 20px;
  }

  &__loaderItem {
    width: calc(25% - 20px);
  }

  &__sliderItemPicture {
    width: 100%;
  }

  &__loaderItemImg {
    width: 100%;
  }

  &__slideOverlay {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #28282899;
    padding: 10px 24px;
    color: $color-white;
    opacity: 0;
    transition: $transition;

    @include mdScreenMedia {
      line-height: 18px;
      padding: 10px;
    }

    @include smScreenMedia {
      padding: 10px 10px;
    }
  }

  &__slideOverlayTitle {
    @include setFont(20px,500);
    margin-bottom: 5px;
  }

  &__slideOverlayPosition {
    @include setFont(14px,400);
    margin-bottom: 5px;
  }

  &__slideOverlayDescription {
    @include setFont(13px,300);
    margin-bottom: 5px;
  }
}

@keyframes displaying {
  from{opacity: 0}
  to{opacity: 1}
}