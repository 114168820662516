@import "../../../styles/vars";
@import "../../../styles/mixins";

.darkLightOption {
  background: $color-white-to-black;
  border-radius: 36px;
  width: 62px;
  height: 34px;
  cursor: pointer;
  position: relative;
  border: 4px solid $color-white-to-black;
  transition: left .3s;

  &_active {
    background: $color-black-to-light-black;

    border: 4px solid $color-black-to-light-black;

  }

  &_footer {
    width: 51px;
    height: 28px;
  }

  &__Item {
    position: absolute;
    height: 100%;
    border-radius: 36px;
    transition: $transition;
    left: 0;
    display: flex;

    &_footer.darkLightOption__Item_active {
      left: calc(100% - 28px);

      .darkLightOption__blackBall {
        background: $color-black-to-light-black;
      }
    }

    &_def.darkLightOption__Item_active {
      left: calc(100% - 35px);

      .darkLightOption__blackBall {
        background: $color-black-to-light-black;
      }
    }

  }

  &__ball {
    border-radius: 50%;
    height: 100%;

    &_def {
      width: 26px;
    }

    &_footer {
      width: 20px;
    }

  }

  &__blackBall {
    background: $color-black-to-white;
    z-index: 0;

    &_footer {
      margin-right: -12px;
    }

    &_def {
      margin-right: -17px;
    }
  }

  &__lightBall {
    background: #FFFFFF;
    z-index: 1;
  }

  &__Icon {
    @include svg(28px, $color-white-to-black)
  }
}

