@import "../../../styles/vars";
@import "../../../styles/mixins";

.policy_container {
   padding-top: 40px;
   padding-bottom: 150px;

  @include vxlScreenMedia {
    padding-top: 30px;
    padding-bottom: 82px;
  }

  @include xxlScreenMedia {
    padding-bottom: 68px;
  }

  @include lgScreenMedia {
    padding-top: 54px;
    padding-bottom: 54px;
  }

  @include mdScreenMedia {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}