@import "../../../styles/vars";
@import "../../../styles/mixins";

.modal {
  width: 100%;
  height: 100%;
  @include flexCol(flex-end,flex-start);
  z-index: 9999;
  margin-top: -10px;
  top: 0;


  &__box {
    position: fixed;
    background: $color-black-to-white;
    z-index: 9999999999;
    max-width: 630px;
    width: 100%;
    margin-left: auto;
    right: 0;
    padding: 24px 40px 0;
    height: 1102px;
    top: 0;
    max-height: 100vh;
    box-shadow: $box-shadow-def;


    @include lgScreenMedia {
      top: 88px;
      padding-right: 20px;
      padding-left: 20px;
      height: 676px;
      width: 320px;
      max-height: calc(100vh - 69px);
      box-shadow: inset 0 2px 12px rgb(0 0 0 / 12%);
    }

    @include smScreenMedia {
      height: 553px;
    }


    

    @media (max-width: 400px) {
      max-width: 272px;
      padding-right: 10px;
      padding-left: 10px;
      height: 611px;
    }
  }

  &__closeBtn {
    top: 24px;
    right: 40px;

    @include lgScreenMedia {
      display: none;
    }

    @include mdScreenMedia {
      right: 20px;
    }

  }



  &__wrapper {
    width: 100%;
  }
}
