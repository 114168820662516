@import "../../../styles/vars";
@import "../../../styles/mixins";

.resetPassword {

  &__title {
    margin-bottom: 40px;
  }

  &__inputBlock {
    margin-bottom: 40px;
    @include smScreenMedia {
      margin-bottom: 35px;
    }
  }

  &__input {
    display: block;
    max-width: 550px;
    width: 100%;

  }

  &__btn {
    display: block;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
  }


  &__invalidText {
    margin-top: -40px;
    margin-bottom: 20px;
  }

}
