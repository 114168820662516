@import "../../../styles/vars";
@import "../../../styles/mixins";

.error {
  &__container {
    max-width: 1385px;
    width: 100%;
    @include flexCol(center);
    gap: 11%;
    margin-left: auto;
    margin-right: auto;
    padding: 5% 20px 8%;

    @include vxlScreenMedia {
      max-width: 71%;
      gap: 6%;
    }

    @include xxlScreenMedia {
      max-width: 68%;
      gap: 8%
    }

    @include xlScreenMedia {
      max-width: 80%;
    }

    @include lgScreenMedia {
      flex-direction: column-reverse;
      gap: 50px;
      padding-top: 10%;
    }

    @include smScreenMedia {
      max-width: 100%;
      gap: 30px;
    }
  }

  &__textBlock {

    @include lgScreenMedia {
     text-align: center;
    }
  }

  &__codeText {
    @include setFont(54px, 600);
    color: $color-white-to-black;
    line-height: 65px;
    padding-bottom: 10%;

    @include lgScreenMedia {
     padding-bottom: 6%
    }
  }

  &__title {
    @include setFont(54px, 600);
    color: $color-white-to-black;
    line-height: 65px;
    padding-bottom: 3%;

    @include vxlScreenMedia {
      font-size: 33px;
      line-height: 46px;
    }

    @include xxlScreenMedia {
      font-size: 19px;
      line-height: 30px;
    }

    @include xlScreenMedia {
      font-size: 18px;
      line-height: 30px;
    }

    @include lgScreenMedia {
      font-size: 22px;
      margin-bottom: 0;
    }

    @include smScreenMedia {
      font-size: 16px;
      line-height: 28px;
    }
  }

  &__img {
    width: 425px;
    height: auto;
    max-width: 100%;

    @include xxlScreenMedia {
      width: 50%;
    }
  }
}