@import "../../../styles/vars";
@import "../../../styles/mixins";

.subscribeForm {
  position: relative;
  @include flexCol(flex-end, stretch);
  max-width: 482px;
  width: 100%;
  -webkit-appearance: none;
  z-index: 1;


  &__inputBlock {
    width: 100%;
    position: absolute;
    height: 100%;

    //@include lgScreenMedia {
    //  position: static;
    //}

  }

  &__inputParent {
    height: 100%;
  }

  &__input {
    width: 100%;
    height: 100%;
    padding: 0 154px 0 12px!important;

    @include vxlScreenMedia {
      padding-right: 134px!important;
    }

    @include xxlScreenMedia {
      padding-right: 124px!important;
    }
  }

  &__invalidText {
    margin-top: 8px;
    margin-bottom: -26px;
    position: absolute;


  }

  &__btn {
    min-width: unset;
    position: relative;
    z-index: 1;
  }

  &__messageModalText {
    font-size: 19px;
    line-height: 31px;

    @include smScreenMedia {
      font-size: 15px;
      line-height: 26px;
    }
  }

}

