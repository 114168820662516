@import "../../../styles/vars";
@import "../../../styles/mixins";

.signInBlock {


  &__title {
    margin-bottom: 36px;

    @include smScreenMedia {
      margin-bottom: 12px;
    }
  }

  &__text {
    margin-bottom: 40px;
  }

  &__inputBlock {
    margin-bottom: 40px;
    @include smScreenMedia {
      margin-bottom: 30px;
    }
  }

  &__input {
    display: block;
    max-width: 550px;
    width: 100%;
  }

  &__btn {
    display: block;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  &__link {
    margin-bottom: 40px;
    display: block;
    text-decoration: none;
    cursor: pointer;

    @include smScreenMedia {
      margin-bottom: 20px;
    }
  }

  &__linkText {
    color: $color-white-to-black;
    margin-right: 8px;

  }

  &__linkIcon {
    @include svg(13px, $color-white-to-black)
  }

  &__invalidText {
    margin-top: -29px;
    margin-bottom: 8px;


    @include lgScreenMedia {
      margin-top: -26px;
    }
  }

}
