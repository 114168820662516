@import "../../../styles/vars";
@import "../../../styles/_mixins";


.sliderDot {
  width: 18px;
  height: 18px;
  background: $color-black-to-white;
  border-radius: 50%;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.12), -2px -2px 12px rgba(255, 255, 255, 0.12);
  margin-top: 5px;

  @include xxlScreenMedia {
    width: 10px;
    height: 10px;
  }


}

