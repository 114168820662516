@import "../../../styles/vars";
@import "../../../styles/mixins";

.header {
  background: radial-gradient(50% 50% at 50% 50%, #{$color-black-to-white-gradient});

  &__container {
    padding-top: 124px;
    padding-bottom: 60px;

    @include vxlScreenMedia {
      padding-top: 104px;
      padding-bottom: 50px;
    }

    @include xxlScreenMedia {
      padding-top: 80px;
    }

    @include lgScreenMedia {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    @include mdScreenMedia {
      padding-top: 40px;
      padding-bottom: 20px;
    }
  }

  &__title {
    @include setFont(45px,500);
    color: $color-white-to-black;
    line-height: 77px;

    @include vxlScreenMedia {
      font-size: 36px;
      line-height: 60px;
    }

    @include xxlScreenMedia {
      font-size: 31px;
      line-height: 50px;
    }

    @include xlScreenMedia {
      font-size: 29px;
      line-height: 47px;
    }

    @include lgScreenMedia {
      font-size: 27px;
      line-height: 43px;
    }
    @include smScreenMedia {
      font-size: 24px;
      line-height: 30px;
    }
  }
}