@import "../../../styles/vars";
@import "../../../styles/mixins";


.profileAccount {
  margin-bottom: 80px;

  &__container {
    background-image: url("../../../assets/images/profile/profileBg.png");
    background-repeat: no-repeat;
    @include flexCol(space-between,flex-end);
    height: 334px;
    padding: 0 20px;
    box-shadow: $box-shadow-def;
    border-radius: $border-radius-def $border-radius-def 0 0;
    background-position: bottom left;

    @include mdScreenMedia {
      height: 250px;
    }

    @include smScreenMedia {
      padding: 0 8px;
      height: 135px;
      background-size: 660px;
    }
  }

  &__infoBlock {
    @include flexCol();
    gap: 14px;
    margin-bottom: -50px;
    z-index: 1;

    @include smScreenMedia {
      margin-bottom: -20px;
    }
  }

  &__uploadImgBox {
    box-shadow: $box-shadow-def;
    border-radius: 50%;
    padding: 10px;
    width: 200px;
    height: 200px;
    position: relative;
    background: $color-black-to-white;

    @include smScreenMedia {
      width: 120px;
      height: 120px;
    }
  }

  &__uploadImgLabel {
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid $color-white-to-black;
    @include flexCol();
    overflow: hidden;
  }

  &__uploadImgLabelText {
    @include setFont(20px, 300);
    color: $color-white-to-black;

    @include smScreenMedia {
      font-size: 12px;
    }
  }

  &__uploadImgInput {
    visibility: hidden;
    width: 0;

  }

  &__uploadedImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__infoTexts {

  }

  &__nameText {
    @include setFont(28px,500);
    color: $color-black-to-light-black;
    margin-bottom: 23px;
    @include xlScreenMedia {
      font-size: 24px;
    }

    @include smScreenMedia {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }

  &__addressText {
    @include setFont(20px,300);
    color: $color-black-to-light-black;
  }

  &__changeButton {

    border: none;
    background: transparent;
    cursor: pointer;
    @include flexCol();
    gap: 8px;
    margin-bottom: 53px;

  }

  &__changeButtonText {
    @include setFont(20px,500);
    color: $color-black-to-light-black;

    @include mdScreenMedia {
      display: none;
    }
  }

  &__changeButtonIcon {
    @include svg(24px,$color-black-to-light-black)
  }

  &__form {
    transform-origin: top;
  }

}
