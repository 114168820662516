@import "../../../styles/vars";
@import "../../../styles/mixins";

.header {
  background: radial-gradient(50% 50% at 76% 50%, #{$color-black-to-white-gradient });

  &__container {
    padding-top: 124px;
    padding-bottom: 121px;

    @include vxlScreenMedia {
      padding-top: 104px;
      padding-bottom: 74px;
    }

    @include xxlScreenMedia {
      padding-top: 80px;
    }

    @include lgScreenMedia {
      padding-top: 40px;
      padding-bottom: 54px;
    }

    @include mdScreenMedia {
      padding-top: 40px;
      padding-bottom: 40px;

    }
  }

  &__main {
    @include flexCol(space-between);
    gap: 50px;

    @include mdScreenMedia {
      flex-direction: column;
    }
  }

  &__mainTextBlock {
    max-width: 670px;
    width: 100%;
  }

  &__title {
    @include setFont(54px, 500);
    line-height: 60px;
    color: $color-white-to-black;
    margin-bottom: 40px;

    @include vxlScreenMedia {
      font-size: 42px;
      line-height: 46px;
      margin-bottom: 26px;
    }

    @include xxlScreenMedia {
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 19px;
    }

    @include xlScreenMedia {
      font-size: 30px;
    }

    @include smScreenMedia {
      font-size: 26px;
      line-height: 28px;
      margin-bottom: 16px;
    }
  }

  &__subscribeForm {
    margin-top: 58px;
    max-width: 482px;

    @include vxlScreenMedia {
      margin-top: 31px;
      max-width: 364px;
    }

    @include xxlScreenMedia {
      margin-top: 26px;
    }

    @include lgScreenMedia {
      display: none !important;
    }
  }

  &__mainImgBlock {
    padding: 40px 46px;
    width: 100%;
    background: $color-black-to-white;


    @include vxlScreenMedia {
      padding: 30px 34px;
    }

    @include xxlScreenMedia {
      padding: 18px 26px;
    }

    @include lgScreenMedia {
      padding: 16px 22px;
    }

    @include smScreenMedia {
      padding: 16px 14px;
    }
  }

  &__video {
    margin: -2px;
    border-radius: 18px;
    overflow: hidden;

    :global(.rh5v-DefaultPlayer_video) {
      //border-radius: 25px;
    }

    :global(.rh5v-DefaultPlayer_controls) {
      border-radius: 0 0 25px 25px;
    }
  }

  &__mainImgBlockInner {
    border: 2px solid $color-white-to-gold;
    border-radius: 18px;
    @include flexCol();
    overflow: hidden;
  }

  &__footBlock {
    margin-top: 95px;
    @include flexCol(space-between);
    gap: 50px;

    @include xxlScreenMedia {
      margin-top: 78px;
    }

    @include lgScreenMedia {
      margin-left: -20px;
      margin-right: -20px;
    }
    @include mdScreenMedia {
      margin-top: 55px;
    }
  }

  &__tabletOrderBtn {
    display: none;
    padding: 10px 38px;
    margin-top: 16px;

    @include lgScreenMedia {
      display: block;
    }

    @include mdScreenMedia {
      display: none;
    }
  }

  &__mobileOrderBtnBlock  {
    margin: 0 auto;
  }

  &__mobileOrderBtn {
    margin: 20px auto 0;
    display: none;
    @include mdScreenMedia {
      display: block;
    }
  }

  &__mainContentText {
    text-align: justify;
  }
}